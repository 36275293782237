import React from 'react';
import { Form, Input, Button, InputNumber, Radio, Select} from 'antd';
import moment from 'moment';
import axios from 'axios';
const { Option } = Select;


const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

function getDuty(){
    var format = 'hh:mm:ss';
    var time = moment(moment(),format);
    let beforeTimeDay = moment('05:00:00', format);
    let afterTimeDay = moment('09:00:00', format);
    let beforeTimeNight = moment('17:00:00', format);
    let afterTimeNight = moment('21:00:00', format);
    console.log(time)
    if (time.isBetween(beforeTimeDay, afterTimeDay)) {
        console.log('day')
        return "Day"
        }
    else if (time.isBetween(beforeTimeNight, afterTimeNight)) {
        console.log('night')
        return "Night"
    } else { 
        console.log('invalid time')
    return "Closed"

    }

}


function EASHealthForm(props) {
    const onFinish = (values) => {
        console.log('Success:', values);
        axios.post("https://temperaturebackend.lentorambulance.com/submitDeclaration", values).then(res=>{
            console.log(res)
            alert(res.data)
            window.location.reload()
        });

      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      getDuty()    
      if (true){

        return (
            <div className="main-body">
                <h3>Kindly make one declaration per callsign within a few hours of the shift start. Thank you.</h3>
                {/* <h3>7pm shift change: Submit before 7pm for outgoing crew and before 8pm for incoming crew</h3> */}
                <br></br><br></br>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
              
              <Form.Item
              label="Pin"
              name="pin"
              rules={[
                {
                  required: true,
                  message: 'Please input pin',
                },
              ]}
            >
              <Input />
            </Form.Item>
      
      
          <Form.Item
              name="callsign"
              label="Callsign"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please select your callsign!',
                },
              ]}
            >
              <Select virtual={false} placeholder="Please select a callsign">
                <Option value="PA121">PA121</Option>
                <Option value="PA131">PA131</Option>
                <Option value="PA132">PA132</Option>
                <Option value="PA133">PA133</Option>
                <Option value="PA151">PA151</Option>
                <Option value="PA221">PA221</Option>
                <Option value="PA222">PA222</Option>
                <Option value="PA223">PA223</Option>
                <Option value="PA311">PA311</Option>
                <Option value="PA331">PA331</Option>
                <Option value="PA332">PA332</Option>
                <Option value="PA333">PA333</Option>
                <Option value="PA334">PA334</Option>
                <Option value="PA431">PA431</Option>
                <Option value="PA432">PA432</Option>
                <Option value="OTHER">OTHER</Option>
              </Select>
            </Form.Item>
      


            <Form.Item name="duty" label="Duty"
            rules={[
              {
                required: true,
                message: 'Please select AM or PM',
                
              },
            ]}>
              <Radio.Group>
                <Radio value="Day">Started shift at 7am</Radio>
                <Radio value="Night">Started shift at 7pm</Radio>
              </Radio.Group>
            </Form.Item>
      
      
              
      
            <Form.Item
              label="Name of Crew Leader"
              name="crew_leader_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Crew Leader Name',
                },
              ]}
            >
              <Input />
            </Form.Item>
      
            <Form.Item
              label="Temperature of Crew Leader"
              name="crew_leader_temperature"
              
      
              rules={[
                {
                  required: true,
                  message: 'Please input Crew Leader Temperature',
                  type: 'number',
                  max: 42,
                  min: 35,
                },
              ]}
            >
              <InputNumber placeholder="Eg. 36.9" />
            </Form.Item>
      
      
            <Form.Item name="crew_leader_well" label="Crew Leader is well and have no symptoms"
            rules={[
              {
                required: true,
                message: 'Please select well or unwell',
              },
            ]}>
              <Radio.Group >
                <Radio required value="well">Well</Radio>
                <Radio value="unwell">Unwell</Radio>
              </Radio.Group>
            </Form.Item>
      
      
            <Form.Item
              label="Name of Crew Member 1"
              name="crew_one_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Crew Member 1',
                },
              ]}
            >
                <Input />
            </Form.Item>
      
            <Form.Item
              label="Temperature of Crew Member 1"
              name="crew_one_temperature"
      
              rules={[
                {
                  required: true,
                  message: 'Please input Crew 1 Temperature',
                  type: 'number',
                  max: 42,
                  min: 35,
                },
              ]}
            >
              <InputNumber placeholder="Eg. 36.9" />
            </Form.Item>
      
      
            <Form.Item name="crew_one_well" label="Crew 1 is well and have no symptoms"
            rules={[
              {
                required: true,
                message: 'Please select well or unwell',
              },
            ]}>
              <Radio.Group>
                <Radio value="well">Well</Radio>
                <Radio value="unwell">Unwell</Radio>
              </Radio.Group>
            </Form.Item>
      
            <Form.Item
              label="Name of Crew Member 2"
              name="crew_two_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Crew Member 2',
                },
              ]}
            >
                <Input />
            </Form.Item>
      
            <Form.Item
              label="Temperature of Crew Two"
              name="crew_two_temperature"
      
              rules={[
                {
                  required: true,
                  message: 'Please input Crew 2 Temperature',
                  type: 'number',
                  max: 42,
                  min: 35,
                },
              ]}
            >
              <InputNumber placeholder="Eg. 36.9" />
            </Form.Item>
      
      
            <Form.Item name="crew_two_well" label="Crew 2 is well and have no symptoms"
            rules={[
              {
                required: true,
                message: 'Please select well or unwell',
              },
            ]}>
              <Radio.Group>
                <Radio value="well">Well</Radio>
                <Radio value="unwell">Unwell</Radio>
              </Radio.Group>
            </Form.Item>
      
      
            <Form.Item
              label="Name of Crew Member 3"
              name="crew_three_name"
              rules={[
                {
                  required: false,
                  message: 'Please input Crew Member 3',
                },
              ]}
            >
                <Input />
            </Form.Item>
      
            <Form.Item
              label="Temperature of Crew 3"
              name="crew_three_temperature"
      
              rules={[
                {
                  required: false,
                  message: 'Please input Crew three Temperature',
                  type: 'number',
                  max: 42,
                  min: 35,
                },
              ]}
            >
              <InputNumber placeholder="Eg. 36.9" />
            </Form.Item>
      
      
            <Form.Item name="crew_three_well" label="Crew 3 is well and have no symptoms">
              <Radio.Group>
                <Radio value="well">Well</Radio>
                <Radio value="unwell">Unwell</Radio>
              </Radio.Group>
            </Form.Item>
      
      
            <Form.Item
              label="Remarks (add details if any crew is unwell or have fever"
              name="remarks"
              rules={[
                {
                  required: false,
                  message: '',
                },
              ]}
            >
                <Input />
            </Form.Item>
      
      
            <Form.Item
              name="start_end"
              label="Confirmation"
              rules={[
                {
                  required: true,
                  message: 'Please confirm that the information is accurate',
                },
              ]}
            >
              <Radio.Group>
                <Radio value={"Start"}>I Confirm The information above is correct</Radio>
              </Radio.Group>
            </Form.Item>
      
          
      
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          </div>
        );

      }
      else{
          return(
              <>
          <h1>Form opens at 5am for morning handover and 5pm for evening handover</h1>
          <h2>Please comeback later and refresh this page. Thank you.</h2>
          </>
          )
        }

}

export default EASHealthForm;
