
import logo from './logo.svg';
import EASHealthForm from './Screens/EASHealthForm';
import StatusCheck from './Screens/StatusCheck';
import './App.css';
import {
  BrowserRouter, Switch, Route, NavLink, useHistory,
} from 'react-router-dom';
import AllSubmissions from './Screens/AllSubmissions';


function App() {
  return (
    <div className="App">
      <h1>Lentor's EMS Health Declaration</h1>
      <Route path="/declare" component={EASHealthForm} />
      <Route path="/status" component={StatusCheck} />
      <Route path="/allSubmissions" component={AllSubmissions} />
    </div>
  );
}

export default App;
