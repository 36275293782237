import React, { useEffect, useState } from 'react';
import {
  Button, Table, Tag, Space, Row, Col,List, Typography, Divider
} from 'antd';
import moment from 'moment';
import axios from 'axios';

//
function AllSubmissions(props) {
    const [state, setState] = useState();
    useEffect(() => {

      // console.log(window.location.search.slice(1,999))
      const apiKey = window.location.search.slice(1,999)
        getData(apiKey)
    }, []);

    async function getData(apiKey){
        let tableDataTodayDayStart=[]
        console.log(apiKey)
        await axios.post("https://temperaturebackend.lentorambulance.com/getSubmissions", {"apiKey":apiKey}).then((response) => {
            setState(response.data)
            console.log(response.data)
  
        });
    }


    const columns = [
        {
            title: 'Date',
            dataIndex: 'dateString',
            key: 'dateString',
          },{
            title: 'Shift Change',
            dataIndex: 'duty',
            key: 'duty',
          },{
            title: 'Start/End of Duty',
            dataIndex: 'start_end',
            key: 'start_end',
          },
        {
          title: 'Time',
          dataIndex: 'timeString',
          key: 'timeString',
        },
        {
          title: 'Callsign',
          dataIndex: 'callsign',
          key: 'callsign',
        },
        {
            title: 'Crew Leader Name',
            dataIndex: 'crew_leader_name',
            key: 'crew_leader_name',
          },
          {
            title: 'Crew Leader Temperature',
            dataIndex: 'crew_leader_temperature',
            key: 'crew_leader_temperature',
          },
          {
            title: 'Crew Leader Health',
            dataIndex: 'crew_leader_well',
            key: 'crew_leader_well',
          },
          {
            title: 'Crew Member 1 Name',
            dataIndex: 'crew_one_name',
            key: 'crew_one_name',
          },
          {
            title: 'Crew Member 1 Temperature',
            dataIndex: 'crew_one_temperature',
            key: 'crew_one_temperature',
          },
          {
            title: 'Crew Member 1 Health',
            dataIndex: 'crew_one_well',
            key: 'crew_one_well',
          },
          {
            title: 'Crew Member 2 Name',
            dataIndex: 'crew_two_name',
            key: 'crew_two_name',
          },{
            title: 'Crew Member 2 Temperature',
            dataIndex: 'crew_two_temperature',
            key: 'crew_two_temperature',
          },{
            title: 'Crew Member 2 Health',
            dataIndex: 'crew_two_well',
            key: 'crew_two_well',
          },{
            title: 'Crew Member 3 Name',
            dataIndex: 'crew_three_name',
            key: 'crew_three_name',
          },{
            title: 'Crew Member 3 Temperature',
            dataIndex: 'crew_three_temperature',
            key: 'crew_three_temperature',
          },{
            title: 'Crew Member 3 Health',
            dataIndex: 'crew_three_well',
            key: 'crew_three_well',
          },{
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
          },
      ];



    if(state){

        return(

            <>
            <h1>All Submissions</h1>
            <input type="text" value={state} />

            <Table columns={columns} dataSource={state} />
           
            </>
        )
    }else{return(<></>)}
    

      
}
  
  export default AllSubmissions;