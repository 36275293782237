import React, { useEffect, useState } from 'react';
import {
  Button, Table, Tag, Space,Row, Col,List, Typography, Divider
} from 'antd';
import moment from 'moment';
import axios from 'axios';

//
function StatusCheck(props) {
    const [stateTodayDayStartIncomplete, setStateTodayDayStartIncomplete] = useState();
    const [state, setState] = useState();

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        let tableDataTodayDayStart=[]
        await axios.post("https://temperaturebackend.lentorambulance.com/getSubmissionStatus", {
  
        }).then((response) => {
            setState(response)
            console.log(response)
        });
    }
    if(state){

        return(

            <>
            <h1>Today's Declaration</h1>
            {/* <h2>Day Shift</h2> */}
            <br></br>
            <Row>
                <Col span={12}>
                    <h2>Day Shift</h2>
                        <List
                        size="small"
                        header={<div>Incomplete</div>}
                        bordered
                        dataSource={state.data.currentDayDayStart.incomplete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                        <List
                        size="small"
                        header={<div>Complete</div>}
                        bordered
                        dataSource={state.data.currentDayDayStart.complete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
    
      
                </Col>
                <Col span={12}>
                    <h2>Night Shift</h2>

                    <List
                        size="small"
                        header={<div>Incomplete</div>}
                        bordered
                        dataSource={state.data.currentDayNightStart.incomplete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                        <List
                        size="small"
                        header={<div>Complete</div>}
                        bordered
                        dataSource={state.data.currentDayNightStart.complete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
    

    
                </Col>
            </Row>
            <br></br><br></br>
            <h1>Yesterday</h1>
            <br></br>
            <Row>
                <Col span={12}>
                    <h4>Day Shift</h4>
    
                    <List
                        size="small"
                        header={<div>Incomplete</div>}
                        bordered
                        dataSource={state.data.yesterdayDayStart.incomplete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                        <List
                        size="small"
                        header={<div>Complete</div>}
                        bordered
                        dataSource={state.data.yesterdayDayStart.complete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
    
      
                </Col>
                <Col span={12}>
                    <h4>Night Shift</h4>
    
    
                    <List
                        size="small"
                        header={<div>Incomplete</div>}
                        bordered
                        dataSource={state.data.yesterdayNightStart.incomplete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                        <List
                        size="small"
                        header={<div>Complete</div>}
                        bordered
                        dataSource={state.data.yesterdayNightStart.complete}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
    
                    
                </Col>
            </Row>
    
    
            </>
        )
    }else{return(<></>)}
    

      
}
  
  export default StatusCheck;